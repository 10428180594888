<template>
  <b-row>
        
    <b-col sm="12">
      <h4 id="traffic" class="card-title mb-0">Servicios</h4>
      <div class="small text-muted">Servicios activos</div>
    </b-col>    

    <b-col class="mt-3">
      <b-table class="mb-0"
              responsive
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="true"
              :items="table.items"
              :fields="table.fields"              
              v-if="table.items.length">  

        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"                    
            :key="field.key"
            :style="{ width: field.width }"
          >
        </template>               

        <template v-slot:cell(services)="data">                            
            <b>{{data.item.service.name}}</b><br>
            {{data.item.service_customer.name}}
        </template>

        <template v-slot:cell(renewal)="data">                            
            <span v-if="data.item.service.mode=='days' || data.item.service.mode=='months' || data.item.service.mode=='years'">
                <b-avatar v-if="data.item.service.automatic_renewal" 
                            variant="secondary" 
                            size="md"
                            :title="'Renovación Automática cada ' + data.item.service.renewal + ' ' + getModeName(data.item.service.mode)"
                            class="align-baseline display-services-legend-renewal"
                            :text="data.item.service.renewal.toString()"
                            badge-variant="success"
                            badge="A">
                </b-avatar>
                <b-avatar v-else 
                            variant="secondary" 
                            size="md"
                            :title="'Renovación Manual cada ' + data.item.service.renewal + ' ' + getModeName(data.item.service.mode)"
                            class="align-baseline display-services-legend-renewal"
                            :text="data.item.service.renewal.toString()"
                            badge-variant="danger"
                            badge="M">
                </b-avatar>                     
            </span>

        </template>                

        <template v-slot:cell(date_init)="data">  
            {{moment(data.item.date_init).format('DD/MM/YYYY')}}
        </template>  

        <template v-slot:cell(date_end)="data">  
            <div v-if="data.item.date_end">
            {{moment(data.item.date_end).format('DD/MM/YYYY')}}
            </div>
        </template>  

        <template v-slot:cell(quantity)="data">                                  
            <div v-if="data.item.service.mode=='hours'">
            {{data.item.quantity}} {{data.item.service.mode}}
            </div>                          
        </template>                                                      

        <template v-slot:cell(price)="data">   
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.full_price.list.currency.code}).format(data.item.price)}}
        </template>

        <template v-slot:cell(total)="data">                                                      
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:data.item.full_price.list.currency.code}).format(getTotalService(data.item))}}
        </template>

        <template v-slot:cell(date_renewal)="data">  
          <div v-if="data.item.canceled">
            <b-badge variant="danger">FINALIZADO</b-badge>
            <div class="crud-services-customers-reasons-canceled">
              <b-icon icon="exclamation-circle" 
                      v-if="data.item.canceled" 
                      class="h4 mb-0" 
                      v-b-tooltip.hover 
                      :title="data.item.reasons_canceled" />                           
            </div>                       
          </div>

          <div v-if="!data.item.canceled" class="pl-2 pr-2">
            <div v-if="daysForRenewal(data.item)<1"
                  class="crud-services-vto-danger">                                 
                  {{moment(data.item.date_renewal).format('DD/MM/YYYY')}} 
                  (Vencido por {{daysVtoRenewal(data.item)}} día/s)
            </div>
            <div v-else>
              <div v-if="data.item.date_renewal" 
                  :class="[daysForRenewal(data.item)<5 ? 'crud-services-vto-warning' : 'crud-services-vto-success']">
                {{moment(data.item.date_renewal).format('DD/MM/YYYY')}} 
                <b>(<span v-html="daysForRenewal(data.item)"></span> día/s)</b>
              </div>
            </div>
            
            <div v-if="data.item.project_id">
              <b-link @click="goProject(data.item)"> 
                <b>
                  <b-icon icon="list-task"></b-icon> Ver Proyecto
                </b>
              </b-link>
            </div>
          </div>
        </template>  
        
      </b-table>
      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
    </b-col>
  </b-row>
</template>
<script>
  import moment from 'moment'

  export default {
    props: [
      'customers',
      'customersID',
    ],    
    data() {
      return {
        table : {
          items: [],
          fields: [                        
            {key: 'services', label: 'Servicio', class:"align-middle", width:"150px"},            
            {key: 'renewal', label: 'Renovación', class:"align-middle text-center", width:"80px"},            
            {key: 'date_init', label: 'Inicio', class:"align-middle text-center", width:"100px"},            
            {key: 'date_end', label: 'Fin', class:"align-middle text-center", width:"100px"},            
            {key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"},            
            {key: 'price', label: 'Importe', class:"align-middle text-right", width:"100px"},     
            {key: 'total', label: 'Total', class:"align-middle text-right", width:"100px"},     
            {key: 'date_renewal', label: 'Renovación', class:"align-middle text-center", width:"180px"},
          ],          
        },       
      }
    },
    watch: {        
      customers: function (newQuestion, oldQuestion) {
          this.load()
      }
    },    
    methods: {
      load() {
        var servicesCustomer = this.customers.services
        var services = []

        if(servicesCustomer) {            
            servicesCustomer.forEach(element1 => {
                var details = element1.details
                if(details) {
                    details.forEach(element2 => {
                        services.push(element2)
                    });
                }                
            });
        }
                  
        if(services){
            this.table.items = services              
        } else {
            this.table.items = []
        }
      },
      getModeName(mode) {
        switch (mode) {
          case 'units':
            return 'UNIDAD/ES'
            break;
          
          case 'hours':
            return 'HORA/S'
            break;

          case 'days':
            return 'DÍA/S'
            break;

          case 'months':
            return 'MES/ES'
            break;

          case 'years':
            return 'AÑO/S'
            break;            
        }
      }, 
      getTotalService(item) {
        if(item.quantity) {
          return item.quantity * item.price
        } else {
          return item.price
        }
      },
      daysForRenewal(item) {        
        var date1 = moment();
        var date2 = moment(item.date_renewal);

        return date2.diff(date1, 'days') + 1;
      },
      daysVtoRenewal(item) {        
        var date1 = moment(item.date_renewal);
        var date2 = moment();

        return date2.diff(date1, 'days') + 1;
      },   

      goProject(item) {
        this.$router.push({ name: 'ProjectStaffDetailHoursProjects', params: { projectID: item.project_id } })                  
      }
    }        
  }
</script>
<style>
    .display-services-legend-renewal {
        cursor: pointer;
    }  
    .crud-services-vto-warning {
        background: goldenrod;
        font-weight: bold;
        color: white;
    }
    .crud-services-vto-success {
        background: darkgreen;
        font-weight: bold;
        color: white;
    }  
    .crud-services-vto-danger {
        background: darkred;
        font-weight: bold;
        color: white;
    }        
</style>